import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import FooterBanner from "../Components/FooterBanner";
import ServiceList from "../Components/Services";
import headPic from "../assets/images/misc/SERVICES.webp";


function Services() {
  const button = {
    text: "Contact Us",
    link: "/country-guide",
  };

  return (
    <div>
      <head>
      <title>Services - CargoTrack | Relocation Experts in KSA</title>
      </head>
      {/* begin */}
      <div>
        <Header />
        {/* home top */}

        <div className="lg:px-[100px] sm:px-[30px] px-[16px] w-full flex lg:flex-row flex-col hight900:h-[800px] hightUt900:h-screen h-full items-center justify-start gap-4 lg:pt-40  pt-20">
          <div className="lg:pt-0 pt-10 gap-5 w-full">
            <p className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">
              Big or Small,
            </p>
            <p className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3">
              we handle it all
            </p>
            <div className="banner_font color-primary-medium"></div>
            <div className="color-primary-medium banner_font_medium mb-4 md:w-[70%] w-[90%] leading-6">
              We deliver more than your expectations by offering complete
              logistics solutions
            </div>
            {button && (
              <a
                href='/contact-us'
                className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[183px] lg:h-[63px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white font-medium hover:bg-[#03719A] duration-300"
              >
                {button.text}
              </a>
            )}
          </div>

          <div
            className="w-full h-full flex items-center lg:justify-end justify-center"
          >

          <img src={headPic} alt="" className="object-cover w-min h-full" />
          </div>
        </div>

        {/* home to ends */}
        <div className="lg:px-[100px] sm:px-[30px] px-[16px]">
          <ServiceList type="" name="Our&nbsp;Services" see_all_btn={false} />
        </div>
        <FooterBanner />
        <Footer />
      </div>
      {/* end */}
    </div>
  );
}

export default Services;
