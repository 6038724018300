import React from "react";
import Slider from "react-slick";
import fast from "../assets/images/herobanner/BG1.jpg";
import kochi from "../assets/images/herobanner/BG2.jpg";
import usa from "../assets/images/herobanner/BG3.jpg";
import dubai from "../assets/images/herobanner/dubai.webp";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import HomeMailTo from "./HomeMailTo";
import { Autoplay } from 'swiper/modules';

function Caurousel() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const Head = () => (
    <div className="flex items-center mb-lg-6 justify-center w-full h-full">
      <Swiper
        autoplay={{
          delay: 4000,
        }}
        loop
        modules={[Autoplay]}
        className="mySwiper lg:h-full max-lg:mt-56"
      >
        {/* 1st slide */}
        <SwiperSlide className="w-full relative  items-center z-10 flex flex-col md:pt-56 pt-20 hight900:h-[900px] h-screen max-lg:h-full">
          <div className="flex items-start flex-col justify-start h-full  shrink-0 text-left z-50 relative">
            <p className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px] lg:ml-[100px] ml-[30px] ">
              We Make Moving
            </p>
            <p className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3 lg:ml-[100px] ml-[30px]">
              Fast & Easy
            </p>
            <p className="lg:text-[24px] text-lg leading-none lg:pb-5 pb-4 text-[#03719A] lg:ml-[100px] ml-[30px]">
              Personal and Commercial <br /> Relocation Services
            </p>

            <a href="/contact-us"
              className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[163px] lg:h-[58px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white  font-medium hover:bg-[#03719A] duration-300  z-[50] lg:ml-[100px] ml-[30px]"
            >
              <span>Contact Us</span>
            </a>
          </div>
          <div className="lg:absolute right-0 lg:bottom-0 top-0 lg:max-w-[60%] flex -z-10 overflow-hidden h-full w-full">
            <img
              alt="image"
              src={fast}
              className="h-full max-lg:h-auto object-contain object-right bottom-0 w-full"
            />
          </div>
        </SwiperSlide>

        {/* 2nd slid */}

        <SwiperSlide className="w-full relative  items-center z-10 flex flex-col md:pt-56 pt-20 hight900:h-[800px] h-screen max-lg:h-full">
          <div className="flex items-start flex-col justify-start h-full  shrink-0 text-left">
            <p className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px] lg:ml-[100px] ml-[30px]">
              Direct Groupage
            </p>
            <p className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3 lg:ml-[100px] ml-[30px]">
              To Europe, Usa & <br />
              Canada
            </p>
            <p className="lg:text-[26px] text-lg leading-none lg:pb-5 pb-4 text-[#03719A] lg:ml-[100px] ml-[30px]">
              Now send your goods to and from <br /> hassle free at best rates
            </p>
            <a href="/contact-us"
              className="  font-vetica cursor-pointer bg-[#00AEEF] lg:w-[183px] lg:h-[63px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white lg:ml-[100px] ml-[30px] font-medium hover:bg-[#03719A] duration-300 z-[500]"
            >
              <span>Contact Us</span>
            </a>
          </div>
          <div className="lg:absolute right-0 lg:bottom-0 top-0 lg:max-w-[60%] flex -z-10 overflow-hidden h-full">
            <img
              src={usa}
              alt="PageTitleImage"
              className="h-full max-lg:h-auto object-contain object-right bottom-0 w-full"
            />
          </div>
        </SwiperSlide>

        {/* 3rd slid*/}
        <SwiperSlide className="w-full relative  items-center z-10 flex flex-col md:pt-56 pt-20 hight900:h-[800px] h-screen max-lg:h-full">
          <div className="flex items-start flex-col justify-start h-full  shrink-0 text-left">
            <p className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] lg:shrink-0 lg:w-max leading-[32px] lg:ml-[100px] ml-[30px]">
              Groupage Services to
            </p>
            <p className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3 lg:ml-[100px] ml-[30px]">
              Hyderabad, Kochi & <br /> Chennai
            </p>
            <p className="lg:text-[26px] text-lg leading-none lg:pb-5 pb-4 text-[#03719A] lg:ml-[100px] ml-[30px]">
              Providing customized and affordable <br /> Groupage Services to
              South India
            </p>
            <a href="/contact-us"
              className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[183px] lg:h-[63px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white lg:ml-[100px] ml-[30px] font-medium hover:bg-[#03719A] duration-300 z-[500]"
            >
              <span>Contact Us</span>
            </a>
          </div>
          <div className="lg:absolute right-0 lg:bottom-0 top-0 lg:max-w-[60%] flex -z-10 overflow-hidden h-full">
            <div className="relative w-full h-full">
              <img
                src={kochi}
                alt="PageTitleImage"
                className="h-full max-lg:h-auto object-contain object-right bottom-0 w-full"
              />
            </div>
          </div>
        </SwiperSlide>

        {/* 4rd slid*/}
        <SwiperSlide className="w-full relative  items-center z-10 flex flex-col md:pt-56 pt-20 hight900:h-[400px] h-screen max-lg:h-full">
          <div className="flex items-start flex-col justify-start h-full  shrink-0 text-left">
            <p className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] lg:shrink-0 lg:w-max leading-[32px] lg:ml-[100px] ml-[30px]">
              LTL Services
            </p>
            <p className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3 lg:ml-[100px] ml-[30px]">
              to Dubai
            </p>
            <p className="lg:text-[26px] text-lg leading-none lg:pb-5 pb-4 text-[#03719A] lg:ml-[100px] ml-[30px]">
              Providing customized and <br /> affordable LTL Services to Dubai
            </p>
            <a href="/contact-us"
              className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[183px] lg:h-[63px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white lg:ml-[100px] ml-[30px] font-medium hover:bg-[#03719A] duration-300 z-[500]"
            >
              <span>Contact Us</span>
            </a>
          </div>
          <div className="lg:absolute right-0 lg:bottom-0 top-0 lg:max-w-[60%] flex lg:w-full -z-10 overflow-hidden h-full">
            <div className="relative w-full h-full">
              <img
                src={dubai}
                effect="opacity"
                loading="lazy"
                alt="PageTitleImage"
                className="h-full max-lg:h-auto object-contain object-right bottom-0 w-full"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );

  return (
    <section
      id="page-title"
      className="flex flex-col items-start justify-center relative max-lg:overflow-hidden bg-white -mt-[80px]"
    >
      <div className="w-full bg-white hight900:h-[800px] h-screen lg:block hidden">
        <Head />
      </div>
      <div className="w-full bg-white h-[600px] lg:hidden block">
        <Head />
      </div>
      {/* there starts mailTo */}

      <div
        className="lg:absolute 2xl:translate-x-[10%] left-0 bottom-0 w-full flex
       lg:px-0 px-[30px] z-50"
      >
        <HomeMailTo />
      </div>
    </section>
  );
}

export default Caurousel;
