import React, { useRef, useState, Fragment } from "react";
import emailjs from "@emailjs/browser";
import { Dialog, Transition } from "@headlessui/react";
import ModelBox from "./ModelBox";

const HomeMailTo = () => {
  let [isOpen, setIsOpen] = useState(false);

  const formRef = useRef(null);
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    moving_from: "",
    moving_to: "",
    moving_date: "",
  });
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert('modal')
    // return
    emailjs
      .sendForm(
        "service_bx9hiz8",
        "template_2jygg49",
        formRef.current,
        "8LlEbwdzoBwdBdcbW"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsOpen(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            moving_from: "",
            moving_to: "",
            moving_date: "",
          });
          // setModalData(prev => ({ ...prev, isopen: true, title: 'Thank You! We’ll be in touch soon!', content: 'We’ve received your request for a quote. You will receive a response from our executive shortly.  ', buttonName: 'OK' }))
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section
      id="content"
      className="bg-transparent w-full flex items-center justify-start "
    >
      <div
        className="flex max-lg:gap-3 lg:flex-row flex-col-reverse items-center justify-between xl:justify-start lg:mx-[100px] w-full"
        style={{
          color: "white",
        }}
      >
        <a
          href="https://drive.google.com/uc?export=download&id=1kBd4c4AE-AIsllxNckaFUmc_AguDI4o4"
          className="max-sm:mt-10"
        >
          <div className="flex flex-row items-center justify-center gap-3 2xl:mr-24 lg:mr-4 mr-0 shrink-0 ">
            <span className=" hidden md:grid xl:hidden place-content-center bg-[#00AEEF] h-[30px] w-[30px] rounded shrink-0">
              <svg
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 14.9991H0V13.5774H10V14.9991ZM5 12.1558L0.714286 7.89089L1.72143 6.88864L4.28571 9.43337V0.782715H5.71429V9.43337L8.27857 6.88864L9.28571 7.89089L5 12.1558Z"
                  fill="white"
                />
              </svg>
            </span>
            <p className="relative text-[#00AEEF] xl:text-[22px] cursor-pointer text-[20px] shrink-0 flex gap-1">
              <span className="lg:hidden xl:block">Download</span> e-brochure
              <span
                className="absolute w-full left-0 bg-[#00AEEF] opacity-50 bottom-0.5"
                style={{ height: "2px" }}
              />
            </p>
            <span className="md:hidden xl:grid grid place-content-center bg-[#00AEEF] h-[30px] w-[30px] rounded shrink-0">
              <svg
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 14.9991H0V13.5774H10V14.9991ZM5 12.1558L0.714286 7.89089L1.72143 6.88864L4.28571 9.43337V0.782715H5.71429V9.43337L8.27857 6.88864L9.28571 7.89089L5 12.1558Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </a>

        <div className="flex-col sm:flex-row flex lg:flex-col 2xl:flex-row xl:items-center xl:justify-center h-20 w-max lg:ml-auto 2xl:ml-0">
          <div className="bgsecondary d-flex align-items-center h-full px-[14px] max-sm:rounded-t-lg sm:rounded-l-lg lg:rounded-l-none lg:rounded-tl-lg 2xl:rounded-l-lg ">
            <div>
              <i className="fa fa-envelope p-2" aria-hidden="true"></i>
            </div>
            <div className="p-2 flex flex-col items-start justify-center">
              <span>Mail us</span>
              <span>wafa.kamil@cargotrack.co</span>
            </div>
          </div>
          <div
            className="d-flex items-center h-full px-[14px] max-sm:rounded-b-lg sm:rounded-r-lg lg:rounded-r-none lg:rounded-bl-lg 2xl:rounded-bl-none w-full"
            style={{
              backgroundColor: "rgb(2,112,155)",
            }}
          >
            <div>
              <i className="fa fa-phone p-2" aria-hidden="true"></i>
            </div>
            <div className="p-2 flex flex-col items-start justify-center shrink-0">
              <span>Call us</span>
              <span className="shrink-0">+966 59 096 7593</span>
            </div>
          </div>
        </div>

        <form
          className="w-full md:max-w-[380px] md:w-auto h-min mb-0"
          ref={formRef}
          style={{
            backgroundColor: "rgb(48,150,190,0.8)",
            borderRadius: "20px 20px 20px 20px",
            padding: "20px",
          }}
        >
          <div className="d-flex justify-content-center align-items-center col-12 ">
            Get a Free Moving Quote
          </div>

          <div className="input-group mb-1  mt-1">
            <input
              name="moving_from"
              onChange={handleChange}
              value={FormData.moving_from}
              type="text"
              className="form-control"
              placeholder="Moving from"
              aria-label="moving-from"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-1">
            <input
              name="moving_to"
              onChange={handleChange}
              value={FormData.moving_to}
              type="text"
              className="form-control"
              placeholder="Moving to"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-1">
            <input
              name="moving_date"
              onChange={handleChange}
              value={FormData.moving_date}
              type="date"
              className="form-control text-gray-500 "
              style={{ marginRight: "2px", borderRadius: "5px" }}
              placeholder="Moving date"
              aria-label="Username"
            />

            <input
              name="name"
              onChange={handleChange}
              value={FormData.name}
              type="text"
              className="form-control"
              style={{ marginLeft: "2px", borderRadius: "5px" }}
              placeholder="Name"
              aria-label="Server"
            />
          </div>

          <div className="input-group mb-1">
            <input
              name="email"
              onChange={handleChange}
              value={FormData.email}
              type="email"
              className="form-control"
              placeholder="E-mail"
              aria-label="E-mail"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-1">
            <input
              name="phone"
              onChange={handleChange}
              value={FormData.phone}
              type="number"
              className="form-control"
              placeholder="Mobile No"
              aria-label="Mobile No"
              style={{ marginRight: "2px", borderRadius: "5px" }}
            />

            {/* <input type="text" className="form-control" placeholder="Server" aria-label="Server" /> */}
            <button
              disabled={!FormData.phone}
              onClick={handleSubmit}
              className="btn btnprimary border-none "
              style={{
                width: "50%",
                height: "40px",
                marginLeft: "2px",
                borderRadius: "5px",
              }}
            >
              Get a Quote
            </button>
          </div>
        </form>
      </div>

      {/* modelBox  */}
      <ModelBox isOpen={isOpen} setIsOpen={setIsOpen} />

      <div></div>
    </section>
  );
};

export default HomeMailTo;
