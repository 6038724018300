import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const LoadCalculator = () => {
  return (
    <>
      <Header />
      <div className="lg:px-[100px] sm:px-[30px] px-[16px] w-full flex items-start justify-start gap-4 md:pt-40 pt-20 mb-20 flex-col">
        <p className="font-vetica lg:text-7xl sm:text-5xl text-4xl text-[#03719A] flex flex-wrap">
          Load&nbsp;
          <span className="font-vetica text-[#00AEEF]">Calculator</span>
        </p>
        <p className="max-w-[700px] sm:mt-2 mt-1 text-[#03719A] font-medium sm:text-lg text-base leading-[176%] font-rubik">
          This tool is designed to help you calculate the weight of your
          shipment as you prepare for your move. With accurate load estimates,
          you can make informed decisions about your moving needs and budget
          accordingly. <br /> <br />
          <span className="font-normal">
            Please keep in mind that the load calculator tool provides estimates
            only. The actual load of your belongings may vary depending on
            several factors. However, this tool is a great starting point to
            help you determine a rough estimate of your total load.
          </span>
        </p>
        <div
          className="grid h-full
         w-full
         mt-12 rounded-[20px] border-[#00AEEF] border-2 overflow-hidden"
        >
          {/* <iframe
            title="packer"
            id="packer"
            src="https://www.searates.com/logistics-explorer/"
            width="750"
            height="500"
            scrolling="Yes"
            align="center"
            frameborder="0"
          /> */}
          <iframe
            title="packer"
            id="packer"
            src="https://www.pier2pier.com/loadcalc/cargo"
            width="750"
            height="700"
            scrolling="Yes"
            align="center"
            frameborder="0"/>
        </div>
        <p className="text-[#03719A] text-center w-full max-w-[600px] mx-auto sm:text-base text-sm font-rubik">
          Please use Google Chrome for the best experience with our load
          calculator tool, as other browsers may not be fully supported.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default LoadCalculator;
