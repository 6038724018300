import React from "react";
import goodluck_img from "../assets/images/misc/ct 3.png";

function FooterBanner() {
  return (
    <section id="content ">
      <div
        className="content-wrap flex items-center justify-center "
        style={{
          overflow: "visible",
          zIndex: "1",
          padding: "0px",
          marginBottom: "-50px",
        }}
      >

        <div className="clearfix d-flex justify-content-center mt-5 px-[30px] lg-[100px] max-w-[900px] w-full">
          <div className="bg-[#00AEEF] rounded-[20px] z-40 relative sm:h-full sm:py-4 flex flex-col justify-center  h-[119px] w-full  pl-5 ">
            <div className="footer-banner-text">
              <div className="footer_banner_font colorwhite sm:text-[32px] text-[17px] pl-5 sm:leading-8  leading-4">
                Do you have <br className="lg:hidden block" /> a requirement ?
              </div>
              <div className="xcolor-primary-medium  footer_banner_font_medium mt-3 lg:flex hidden pl-5 text-[#002A39] text-lg leading-7 ">
                We provide better freight & service in the <br />
                kingdom of Saudi Arabia with our expertise
              </div>
              <a
                href={"/get-quote"}
                className="btn btnprimary mt-3 img-footer ml-5  lg:w-[120px] lg:h-[43px] flex items-center justify-center h-[36px] w-[88px] rounded-[4px] lg:rounded-[5px] hover:bg-[#002A39] duration-300 min-w-auto max-w-max text-[13px]"
                placeholder=""
                style={{ width: "auto", height: "35px", borderRadius: "5px" }}
              >
                Get a Quote
              </a>
            </div>
            <img
              src={goodluck_img}
              alt="goodluck"
              className="absolute -z-10 lg:right-0 -right-8 lg:h- sm:h-[120%] h-[127px] object-contain bottom-0 w-auto"
              />
          </div>
              </div>
        </div>
    </section>
  );
}

export default FooterBanner;
