import React from "react";
import detailPic from "../assets/images/misc/Group 794.webp";


function SmallDetail() {
  return (
    <div className="flex  flex-col-reverse w-full items-center justify-between gap-5 lg:grid  grid-cols-2">
      <div className="grid w-full">

        <img src={"images/misc/Group 971.png"} height="100" className="object-contain w-[250px] lg:w-[343px] " alt="" />
        <div className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px]">
          The Best{" "}
          <span className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">
            {" "}
            Moving
          </span>{" "}
        </div>
        <div className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px]">
          Company in the{" "}
        </div>
        <div className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">Kingdom</div>
        <p className="mt-4 mb-4 text-[18px] text-lg lg:text-[26px] leading-[30px] text-[#03719A]">
          Personal and Commercial <br /> Relocation Services
        </p>
        <a
          href="/contact-us"
          className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[163px] lg:h-[58px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[13px] text-white  font-medium hover:bg-[#03719A] duration-300"
        >
          <span>Contact Us</span>
        </a>
      </div>
      <div className="h-full w-full flex justify-end">
        <img src={detailPic} alt="" className="object-contain h-full w-full" />
      </div>
    </div>
  );
}

export default SmallDetail;
