import React from "react";
import bgimg from "../assets/images/misc/622088 (1)-min.jpg";

function QuestionBanner() {
  return (
    <div className="xsection row p-0 align-items-stretch topmargin-sm px-[0] lg:mx-[100px] relative grid lg:grid-cols-2 lg:gap-9 ">
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5%",
          marginTop: "5%",
        }}
      >
        <div className="w-full rounded-none lg:rounded-[30px]  flex items-start h-full flex-col relative sm:px-[80px] px-[30px] sm:py-[80px] py-5 overflow-hidden bg-black bg-opacity-50">
          <img
            src={bgimg}
            alt=""
            className="object-cover h-full w-full absolute -z-10 bottom-0 left-0 top-0"
          />
          <div className="heading-small banner_font text-[25px] md:text-[32px] font-semibold leading-7 text-white">
            Are you considering a{" "}
          </div>
          <div
            className="heading-small 
          text-[25px] md:text-[32px] font-semibold text-white"
          >
            relocation to Saudi Arabia?
          </div>
          <div className="normal-text-large mt-2 lg:mb-5 mb-4 text-lg md:text-xl font-medium text-white">
            Get in touch with us for assistance
          </div>
          <a
            className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[149px] lg:h-[53px] w-[109px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[20px] text-[13px] text-white  font-medium hover:bg-[#03719A] duration-300"
            href="/country-guide"
          >
            Country Guide
          </a>
        </div>
      </div>

      {/*  */}

      <div className="flex w-full lg:my-[5%] max-sm:">
        <div className="rounded-none lg:rounded-[30px]   items-start h-full flex-col relative overflow-hidden border-2 border-[#03719A] sm:px-[80px] px-[30px] sm:py-[80px] py-5 shadow-sm flex justify-center ">
          <h5 className="text-[#03719A] font-semibold lg:text-[32px] text-[25px] leading-[125%] font-rubik">
            Effortlessly Determine Your Total Load with Our Online Load
            Calculator Tool
          </h5>
          <a
            className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[190px] lg:h-[53px] w-[130px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[20px] text-[13px] text-white  font-medium hover:bg-[#03719A] duration-300 mt-3"
            href="/load-calculator"
          >
            Try Load Calculator
          </a>
        </div>
      </div>
    </div>
  );
}

export default QuestionBanner;
